import {ActionIcon, Badge, Box, Button, createStyles, getStylesRef, Group, px, rem, Tooltip} from "@mantine/core";
import {ChangeEvent, useState} from "react";
import {archiveTask, deleteTask, unarchiveTask, updateTaskStatus} from "../services/api";
import {openContextModal} from "@mantine/modals";
import dayjs, {Dayjs} from "dayjs";
import {Link} from 'react-router-dom'

import {ItemLineItem, TaskEvent} from "../lib/schema";
import { IconArchive, IconEditCircle, IconRestore, IconTrashOff } from "@tabler/icons-react";

const useStyles = createStyles((theme) => ({
    item: {
        borderRadius: theme.radius.sm,
        paddingTop: theme.spacing.xs,
        paddingBottom: theme.spacing.xs,
        paddingLeft: theme.spacing.sm,
        paddingRight: theme.spacing.sm,
        '&:hover': {
            backgroundColor: theme.colors.gray[1],
            [`& .${getStylesRef('actions')}`]: {
                display: "flex"
            },
        },
    },
    actions: {
        ref: getStylesRef('actions'),
        display: "none"
    },
    content: {
        color: theme.colors.gray[9],
        textDecoration: "none",
        lineHeight: "1.75rem",
    },
    checked: {
        textDecoration: "line-through",
        color: theme.colors.gray[6]
    },
    mark: {
        color: theme.colors.red[9],
        fontWeight: "bold",
        paddingRight: theme.spacing.xs
    },
    group: {
        minWidth: rem("5vh")
    },
    event: {
        marginLeft: px(theme.spacing.xl) * 3.3,
        color: theme.colors.gray[7],
        fontSize: theme.fontSizes.xs,
        paddingTop: px(theme.spacing.xs)*0.25,
        paddingBottom: px(theme.spacing.xs)*0.25,
    }

}));

interface TaskEventBadgeProps extends TaskEvent {

}


const valueConverter = (event: TaskEventBadgeProps) => {
    switch (event.event_type) {
        case "Create":
            return "创建"
        case "Done":
            return "已完成"
        case "Open":
            return "打开"
        case "Archived":
            return "归档"
        case "Unarchived":
            return "恢复"
        case "UpdateState":
            return `${event.state}`
        case "CreateComment":
            return "备注"
    }
}

function TaskEventBadge(props: TaskEventBadgeProps) {

    return (
        <Tooltip label={dayjs(props.datetime).format("HH:mm:ss")}>
            <Badge>{valueConverter(props)}</Badge>
        </Tooltip>
    )
}


interface Props extends ItemLineItem {
    grouped_day?: Dayjs,
}

export default function TimelineTaskItem(props: Props) {

    const {classes, cx} = useStyles();
    const [checked, setChecked] = useState(props.done);


    const handleClick = async (e: ChangeEvent<HTMLInputElement>) => {
        setChecked(e.currentTarget.checked)

        const status = e.currentTarget.checked ? "Done" : "Open"
        await updateTaskStatus(props.id, status);
    }

    const handleUpdateState = async (state: string) => {
        await updateTaskStatus(props.id, state);
    }

    const openEditModel = () => {
        openContextModal({
            modal: "taskItemEditModal",
            title: 'Editing task Item',
            centered: true,
            size:"xl",
            innerProps: props,
        })
    }

    const current_index = (props.states ?? []).findIndex(it => it === props.current_state)
    const prevState = props.states?.[current_index - 1]
    const nextState = props.states?.[current_index + 1]
    let day_events: TaskEvent[] = [];
    if (props.grouped_day !== undefined) {
        day_events = props.events
            .filter(event => dayjs(event.datetime).format("MMM D, YYYY") === dayjs(props.grouped_day).format("MMM D, YYYY"))
            .reverse();
    }

    return (
        <Box>
            <Group className={cx(classes.item)} position={"apart"}>
                <Group>
                    <Group spacing={"xs"}>
                    <Badge color="gray" size="md" variant="dot">{props.group}</Badge>

                        <Box component={Link} to={`/tasks/${props.id}`}
                             className={cx(classes.content, {[classes.checked]: props.done || props.archived})}>
                            {props.priority > 0 && <span className={classes.mark}>{"!".repeat(props.priority)}</span>}
                            {props.content}
                        </Box>
                        {props.habit_id && <Badge color="gray" variant="outline">{props.habit_name}</Badge>}
                        {props.archived && <Badge color="gray" variant="outline">ARCHIVED</Badge>}
                    </Group>

                </Group>
                <Group spacing={"xs"} className={classes.actions}>
                    {(!props.archived && prevState !== undefined) &&
                    <Button color="gray" size="xs" compact onClick={() => handleUpdateState(prevState)}>
                        Back to {prevState}
                    </Button>
                }
                    {(!props.archived && nextState !== undefined) &&
                    <Button size="xs" compact onClick={() => handleUpdateState(nextState)}>
                        Goto {nextState}
                    </Button>
                }
                    {!props.done && !props.archived &&
                    <ActionIcon onClick={() => openEditModel()}>
                        <IconEditCircle size="1.125rem"/>
                    </ActionIcon>
                }
                    {props.archived ?
                    <ActionIcon onClick={() => unarchiveTask(props.id)}>
                        <IconRestore size="1.125rem"/>
                    </ActionIcon>
                    : <ActionIcon onClick={() => archiveTask(props.id)}>
                        <IconArchive size="1.125rem"/>
                </ActionIcon>}
                    <ActionIcon color="pink" onClick={() => deleteTask(props.id)}>
                        <IconTrashOff size="1.125rem"/>
                    </ActionIcon>
                </Group>
            </Group>
        </Box>

    )
}