import {Box, createStyles,} from "@mantine/core";


const useStyles = createStyles((theme) => ({


    item: {
        borderRadius: theme.radius.xl,
        lineHeight: theme.fontSizes.xs,
        fontSize: theme.fontSizes.xs,
        padding: theme.spacing.xs,
        textAlign: "center",
        cursor: "pointer", margin: 0
    },
    checked: {
        backgroundColor: theme.colors.blue[6],
        color: theme.white

    }

}));

interface Props {
    content: string,
    checked: boolean
}

export default function Chipper(props: Props) {
    const {classes, cx} = useStyles();

    return (<Box className={cx(classes.item, {[classes.checked]: props.checked})}>
        {props.content}
    </Box>)
}