import {Avatar, Box, Button, Container, Divider, Group, Text} from "@mantine/core";
import NavBar from "../components/NavBar";
import useUserSession from "../hooks/useUserSession";
import dataPrivacy from "../static/bendy-user-authentication-in-mobile-application.png"
import basicCV from "../static/bendy-recruiter-chooses-the-best-candidate-cv.png"
import {dataExport} from "../services/api";
import {notifications} from "@mantine/notifications";
import {IconCheck} from "@tabler/icons-react";
import React from "react";

export default function Profile() {
    const { user} = useUserSession()


    const onDataExport = async () => {
        const res = await dataExport();
        const data = JSON.stringify(res, null, 4);
        const url = window.URL.createObjectURL(
            new Blob([data]),
        );
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute(
            'download',
            `toodoo_data.json`,
        );

        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();

        // Clean up and remove the link
        // @ts-ignore
        link.parentNode.removeChild(link);
        notifications.show({
            title: '导出成功',
            message: '数据已成功导出，并下载成功',
            icon: <IconCheck/>
        })
    }

    return (
        <Container mt={"3rem"}>
            <NavBar/>


            <Box m={"2rem"}>

                <Group noWrap>
                    <Avatar src={basicCV} radius="xl" size="xl"/>
                    <div>
                        <Text>基本信息</Text>
                        <Text size="sm" color="dimmed" weight={400}>
                            账号 & 邮箱等
                        </Text>
                    </div>
                </Group>
                <Box m={"1rem"}>
                    <Group position={"apart"} my={"0.5rem"}>
                        <Text>用户名</Text>
                        <Text>{user?.username}</Text>
                    </Group>
                    <Group position={"apart"} my={"0.5rem"}>
                        <Text>邮箱</Text>
                        <Text>{user?.email}</Text>
                    </Group>
                    <Group position={"apart"} my={"0.5rem"}>
                        <Text>用户权限</Text>
                        <Text>{user?.roles}</Text>
                    </Group>
                </Box>
                <Divider variant="dashed" my={"1rem"}></Divider>
            </Box>

            <Box m={"2rem"}>

                <Group noWrap>
                    <Avatar src={dataPrivacy} radius="xl" size="xl"/>
                    <div>
                        <Text>数据 & 账号</Text>
                        <Text size="sm" color="dimmed" weight={400}>
                            管理、导出你的数据和账号移除
                        </Text>
                    </div>
                </Group>
                <Box m={"1rem"}>
                    <Group position={"apart"} my={"0.5rem"}>
                        <Text>导出所有数据</Text>
                        <Button variant="default" onClick={onDataExport}>下载</Button>
                    </Group>
                </Box>

            </Box>

        </Container>
    );
}