import {ContextModalProps} from "@mantine/modals";
import {Button, Group, Stack, TextInput} from "@mantine/core";
import PreviewTaskItem from "../components/PreviewTaskItem";
import React, {useMemo, useState} from "react";
import {parseTask} from "../utils/taskParser";
import {updateTask} from "../services/api";
import {notifications} from "@mantine/notifications";
import {IconCheck} from "@tabler/icons-react";


interface Props {
    id: string,
    priority: number,
    content: string,
    group: string,
    task_type: "Todo" | "Stateful",
    create_at: string,
    events: any[],
    done: boolean,
    archived: boolean,
    current_state: string,
    states: string[],
    habit_id?: string,
    habit_name?: string
}

export default function TaskItemEditModal({context, id, innerProps: props}: ContextModalProps<Props>) {
    const originalContent = (props.priority > 0 ? `${"!".repeat(props.priority)} ` : "") + props.content + (props.states ? ` [[${props.states.join(">")}]]` : "") + (props.group !== "default" ? ` +${props.group}` : "")

    const [newTaskText, setNewTaskText] = useState(originalContent);

    const parsedTask = useMemo(() => parseTask(newTaskText), [newTaskText])

    const handleSubmitClick = async () => {
        await updateTask(props.id, parsedTask.task_type, parsedTask.priority, parsedTask.content, parsedTask.group, parsedTask.states)
        notifications.show({
            title: '更改成功',
            message: '更改成功',
            icon: <IconCheck/>
        })
        context.closeModal(id)
    }
    return (
        <Stack>
            <TextInput size="md" value={newTaskText} onChange={e => setNewTaskText(e.target.value)}/>
            <PreviewTaskItem {...parsedTask} id={"previewTask"} done={false}
                             archived={false}
                             current_state={parsedTask.states?.[0]}/>

            <Group position={"right"}>
                <Button color="gray" disabled={originalContent === newTaskText}
                        onClick={() => setNewTaskText(originalContent)}>重置</Button>
                <Button disabled={originalContent === newTaskText} onClick={handleSubmitClick}>提交更改</Button>

            </Group>
        </Stack>
    )
}