import {Accordion, Avatar, Badge, Button, Center, Group, Stack, Text} from "@mantine/core";
import Chipper from "./basic/Chipper";
import {Calendar} from "@mantine/dates";
import dayjs from "dayjs";
import useSWR, {mutate} from "swr";
import {disableHabit, enableHabit, fetcher} from "../services/api";


interface Props {
    id: string,
    name: string,
    trigger_days: string[]
    enabled: boolean
}

export default function HabitItem(props: Props) {


    const {data: calenderDates} = useSWR<string[]>(`/habits/${props.id}/calendar`, fetcher);
    const minDate = dayjs().subtract(2, "month").toDate();


    const handleSubmit = async () => {
        if (props.enabled) {
            await disableHabit(props.id);

        } else {
            await enableHabit(props.id);
        }
        await mutate("/habits")
    }
    return (
        <Accordion variant="filled">
            <Accordion.Item value="archivedTasks">
                <Accordion.Control>
                    <Group position={"apart"}>

                        <Group> <Badge>{props.enabled ? "有效" : "暂停"}</Badge><Text>{props.name}</Text></Group>

                        <Group spacing={"xs"}>
                            <Chipper checked={props.trigger_days.includes("Sunday")} content={"日"}></Chipper>
                            <Chipper checked={props.trigger_days.includes("Monday")} content={"一"}></Chipper>
                            <Chipper checked={props.trigger_days.includes("Tuesday")} content={"二"}></Chipper>
                            <Chipper checked={props.trigger_days.includes("Wednesday")}
                                     content={"三"}></Chipper>
                            <Chipper checked={props.trigger_days.includes("Thursday")} content={"四"}></Chipper>
                            <Chipper checked={props.trigger_days.includes("Friday")} content={"五"}></Chipper>
                            <Chipper checked={props.trigger_days.includes("Saturday")} content={"六"}></Chipper>
                        </Group>
                    </Group>
                </Accordion.Control>
                <Accordion.Panel>
                    <Center>


                        <Stack>
                            <Group>
                                <Button
                                    onClick={handleSubmit}>{props.enabled ? "暂停" : "开启"}</Button>
                            </Group>

                            <Calendar static defaultLevel={"month"} numberOfColumns={3}
                                      firstDayOfWeek={0}
                                      date={minDate}
                                      renderDay={(date) => {
                                          const target_date = dayjs(date);
                                          const hasDate = (calenderDates ?? []).some(one => dayjs(one).format("YYYY-MM-DD") === target_date.format("YYYY-MM-DD"));
                                          return (
                                              <Avatar radius="xl"
                                                      size="sm"
                                                      variant={hasDate ? "filled" : "light"}
                                                      color={hasDate ? "blue" : "gray"}>{target_date.format("D")}</Avatar>
                                          );
                                      }} placeholder={undefined} onPointerEnterCapture={undefined}
                                      onPointerLeaveCapture={undefined}                                      />
                        </Stack>
                    </Center>
                </Accordion.Panel>
            </Accordion.Item>
        </Accordion>
    )
}