import {NavLink} from 'react-router-dom'

import CanAccess from "./CanAccess";
import useUserSession from "../hooks/useUserSession";
import {ActionIcon, createStyles, Divider, Group, rem} from "@mantine/core";
import {IconLogout} from "@tabler/icons-react";
import useSWR from "swr";
import {fetcher} from "../services/api";
import {filter} from "lodash";


const useStyles = createStyles((theme) => ({
    host: {},
    profile: {
        fontSize: theme.fontSizes.lg,
        color: theme.colors.gray[9],
        textDecoration: "none"
    },
    active: {
        fontSize: rem("2rem"),
        color: theme.colors.gray[9],
        textDecoration: "none"
    },
    inactive: {
        fontSize: theme.fontSizes.lg,
        color: theme.colors.gray[6],
        textDecoration: "none"
    }

}));

function NavBar() {
    const {isAuthenticated, user, signOut} = useUserSession()
    const {classes} = useStyles();
    const {data: tasks} = useSWR(isAuthenticated ? "/tasks" : null, fetcher);

    const todoTasksNumber = filter(tasks ?? [], item => item.archived === false && item.done === false).length
    return (
        <>
            <Group position={"apart"} m={"1rem"}>
                <Group>
                    <NavLink to="/tasks" className={({isActive}: { isActive: boolean }) =>
                        isActive ? classes.active : classes.inactive
                    }>今日待办{todoTasksNumber > 0 ? `(${todoTasksNumber})` : ""}</NavLink>
                    <NavLink to="/timeline" className={({isActive}: { isActive: boolean }) =>
                        isActive ? classes.active : classes.inactive
                    }>时间线</NavLink>
                    <NavLink to="/habits" className={({isActive}: { isActive: boolean }) =>
                        isActive ? classes.active : classes.inactive
                    }>习惯</NavLink>

                    <CanAccess permissions={['users.list']}>
                        <NavLink to="/users">Users</NavLink>
                    </CanAccess>

                    <CanAccess permissions={['metrics.list']}>
                        <NavLink to="/metrics">Metrics</NavLink>
                    </CanAccess>
                </Group>


                {isAuthenticated && (
                    <Group>
                        <NavLink to="/profile" className={classes.profile}>{user?.username}</NavLink>
                        <ActionIcon onClick={() => signOut("/")}>
                            <IconLogout size="1.125rem"/>
                        </ActionIcon>
                    </Group>
                )}
            </Group>
            <Divider/>
        </>

    )
}

export default NavBar
