import {
    Accordion,
    ActionIcon,
    Blockquote,
    Box,
    Container,
    Divider,
    Group,
    Kbd,
    rem,
    Skeleton,
    Stack,
    Text,
    TextInput,
    useMantineTheme
} from "@mantine/core";
import NavBar from "../components/NavBar";
import {IconArchive, IconArrowRight, IconLivePhoto} from "@tabler/icons-react";
import TaskGroup from "../components/TaskGroup";
import TaskItem from "../components/TaskItem";
import {useMemo, useState} from "react";
import {api, fetcher} from "../services/api";
import {getHotkeyHandler, useOs} from "@mantine/hooks";
import useSWR, {useSWRConfig} from "swr";
import {filter, groupBy, orderBy} from "lodash";
import ArchivedTaskItem from "../components/ArchivedTaskItem";
import {parseTask} from "../utils/taskParser";
import PreviewTaskItem from "../components/PreviewTaskItem";

function Today() {
    const os = useOs();
    const {mutate} = useSWRConfig()
    const theme = useMantineTheme();
    const [newTaskText, setNewTaskText] = useState("");

    const parsedTask = useMemo(() => parseTask(newTaskText), [newTaskText])

    const {data: tasks, isLoading} = useSWR("/tasks", fetcher);


    const groupedTasks = useMemo(() => {
        return groupBy(filter((tasks ?? []), item => item.archived !== true), it => it.group)
    }, [tasks]);

    const archivedTasks: any[] = useMemo(() => {
        return (tasks ?? []).filter((item: any) => item.archived === true);
    }, [tasks]);

    const handleNewTask = async () => {

        await api.post("/tasks", parsedTask);
        setNewTaskText("")
        await mutate("/tasks")
    }


    return (
        <Container mt={"3rem"}>
            <NavBar/>

            <Box mt={"1rem"}>
                <TextInput
                    size="md"
                    value={newTaskText}
                    onChange={e => setNewTaskText(e.target.value)}
                    placeholder="Input a new task"
                    rightSection={
                        <ActionIcon disabled={newTaskText.trim() === ""} onClick={handleNewTask} size={28} radius="xl"
                                    color={theme.primaryColor} variant="filled">
                            <IconArrowRight size="1.1rem" stroke={1.5}/>
                        </ActionIcon>

                    }
                    rightSectionWidth={42}
                    onKeyDown={getHotkeyHandler([
                        ['mod+Enter', handleNewTask],
                    ])}
                />

                <Blockquote>
                    <Stack>

                        <Accordion
                            variant="filled"
                        >
                            <Accordion.Item value="Preview">
                                <Accordion.Control icon={<IconLivePhoto size={rem(20)}/>}>
                                    预览模式 (点击展开)
                                </Accordion.Control>
                                <Accordion.Panel>
                                    {newTaskText.trim() !== "" &&
                                        <PreviewTaskItem {...parsedTask} id={"previewTask"} done={false}
                                                         archived={false} current_state={parsedTask.states?.[0]}/>
                                    }

                                </Accordion.Panel>
                            </Accordion.Item>
                        </Accordion>

                        <Group spacing={"xl"}>
                            <Text>加入分组: <Kbd>+Group</Kbd></Text><Text>状态机流转: <Kbd>[[打开 &gt; 等待回复 &gt; 完成]]</Kbd></Text>
                            <Text>优先级设定: <Kbd>!!!</Kbd></Text>
                            <Text>新建快捷键: <Kbd>{os === "macos" ? "Command" : "Control"} + Enter</Kbd></Text>
                        </Group>
                    </Stack>


                </Blockquote>
            </Box>
            {isLoading ?
                <Stack>
                    <Skeleton height={36} radius="md"/>
                    <Skeleton height={36} radius="md"/>
                    <Skeleton height={36} radius="md"/>
                    <Skeleton height={36} radius="md"/>
                </Stack> :
                <>
                    <Box>
                        {
                            Object.keys(groupedTasks).sort().map(groupName => <TaskGroup key={groupName}
                                                                                         name={groupName}>
                                {
                                    orderBy(groupedTasks[groupName], ["done", "priority", "create_at"], ["asc", "desc", "asc"]).map(task =>
                                        <TaskItem key={task.id} {...task}></TaskItem>
                                    )
                                }
                            </TaskGroup>)
                        }
                    </Box>

                    {archivedTasks.length > 0 &&
                        <>
                            <Divider variant="dashed" my={"1rem"}></Divider>
                            <Accordion
                                variant="filled"
                            >
                                <Accordion.Item value="archivedTasks">
                                    <Accordion.Control icon={<IconArchive size={rem(20)}/>}>
                                        Archived Tasks
                                    </Accordion.Control>
                                    <Accordion.Panel><Box>
                                        {archivedTasks.map(task => (
                                            <ArchivedTaskItem key={task.id} {...task}></ArchivedTaskItem>))}
                                    </Box></Accordion.Panel>
                                </Accordion.Item>
                            </Accordion>
                        </>
                    }
                </>
            }
        </Container>
    )
}

export default Today
