import {useParams} from "react-router-dom";
import useSWR from "swr";
import {Box, Button, Card, Container, Divider, Grid, Group, Text, Textarea, Timeline} from "@mantine/core";
import {fetcher, submitNewTaskComment} from "../services/api";
import NavBar from "../components/NavBar";
import {IconCheck, IconStatusChange} from "@tabler/icons-react";
import dayjs from "dayjs";
import ReactMarkdown from "react-markdown";
import React, {useState} from "react";
import {notifications} from "@mantine/notifications";
import TaskItemDetail from "../components/TaskItemDetail";

export const eventTypeConverter = (event: any) => {
    switch (event.event_type) {
        case "Create":
            return "创建"
        case "Done":
            return "已完成"
        case "Open":
            return "打开"
        case "Archived":
            return "归档"
        case "Unarchived":
            return "从 归档 状态恢复"
        case "UpdateState":
            return `更新状态至: ${event.state}`
        case "CreateComment":
            return "新增备注"
    }
}


export default function TaskDetail() {
    let {id} = useParams();
    const [comment, setComment] = useState("");
    const {data: task, isLoading} = useSWR<{
        content: string,
        events: any[],
        comments: any[]
    }>(`/tasks/${id}`, fetcher);
    const onSubmit = async () => {
        await submitNewTaskComment(id!, comment);
        notifications.show({
            title: '提交备注成功',
            message: '提交备注成功',
            icon: <IconCheck/>
        })
        setComment("");
    }

    return (
        <Container mt={"3rem"}>
            <NavBar/>

            {!isLoading && task &&

                <Box m={"2rem"}>
                    {/*// @ts-ignore*/}
                    <Text fz="xl"><TaskItemDetail {...task}/></Text>
                    <Grid mt={"2rem"}>
                        <Grid.Col span={9}>

                            {task.comments.length === 0 ?
                                <Group my="xl" position="center">
                                    <Text size="sm" color="dimmed">No Comments</Text>

                                </Group>

                                : task.comments.map(comment => <Card shadow="sm" padding="lg" my="xs" radius="md"
                                                                     withBorder>
                                    <Card.Section px="lg">
                                        <Group position="apart" mt="md" mb="xs">
                                            <Text size="sm"
                                                  color="dimmed">{dayjs(comment.create_at).format("YYYY-MM-DD HH:mm:ss")}</Text>
                                        </Group>

                                    </Card.Section>
                                    <ReactMarkdown children={comment.content}/>
                                </Card>)}

                            <Divider variant="dashed" my={"1rem"}></Divider>
                            <Card shadow="sm" padding="lg" radius="md" withBorder>
                                <Card.Section px="lg">
                                    <Group position="apart" mt="md" mb="xs">
                                        <Text size="sm" color="dimmed">新备注</Text>
                                    </Group>
                                </Card.Section>
                                <Textarea
                                    placeholder="Your comment"
                                    value={comment} onChange={(event) => setComment(event.currentTarget.value)}
                                />
                                <Card.Section px="lg">
                                    <Group position="apart" mt="md" mb="xs">
                                        <div></div>
                                        <Button disabled={comment.trim() === ""} onClick={onSubmit}>提交</Button>
                                    </Group>
                                </Card.Section>

                            </Card>
                        </Grid.Col>

                        <Grid.Col span={3}>
                            <Timeline active={task.events.length} bulletSize={24} lineWidth={2}>
                                {task.events.map(event =>
                                    <Timeline.Item bullet={<IconStatusChange size={12}/>}
                                                   title={eventTypeConverter(event)}>
                                        <Text size="xs"
                                              mt={4}>{dayjs(event.datetime).format("YYYY-MM-DD HH:mm:ss")}</Text>
                                    </Timeline.Item>)}

                            </Timeline>
                        </Grid.Col>

                    </Grid>
                </Box>
            }
        </Container>
    )
}