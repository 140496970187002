import NavBar from "../components/NavBar";
import {Box, Container, Text} from "@mantine/core";
import {findLast, groupBy, sortBy} from "lodash";
import useSWR from "swr";
import {fetcher} from "../services/api";
import {useMemo} from "react";
import dayjs from 'dayjs'
import TimelineTaskItem from "../components/TimelineTaskItem";
import {ItemLineItem} from "../lib/schema";




export default function Timeline() {

    const {data: timelineTasks} = useSWR<ItemLineItem[]>("/timelines", fetcher);

    const dayTasks = useMemo(() => {
        let datedTasks :{ [date:string]: Set<ItemLineItem> }= {};

        (timelineTasks??[]).forEach(item => {
            item.events.forEach(event => {
                const day = dayjs(event.datetime).format("YYYY-MM-DD");
                if(day in datedTasks) {
                    datedTasks[day].add(item);
                }else {
                    datedTasks[day] = new Set();
                    datedTasks[day].add(item);
                }
            })
        });
        return sortBy(Object.keys(datedTasks), o => dayjs(o)).map(date => {
            return {
                date, tasks: sortBy(Array.from(datedTasks[date].values()), o => {
                    const event = findLast(o.events)
                    return dayjs(event?.datetime)
                }).reverse()
            }
        }).reverse()

    }, [timelineTasks])

    return (
        <Container mt={"3rem"}>
            <NavBar/>
            <Box mt={"1rem"}>
                {dayTasks.map(group => (
                    <Box key={group.date} mt={"1rem"}>
                        <Text>{`${dayjs(group.date).format("dddd,  MMM D, YYYY")}`}</Text>
                        <Box>
                            {group.tasks.map(task => (
                                <TimelineTaskItem key={task.id} {...task} grouped_day={dayjs(group.date)} ></TimelineTaskItem>
                            ))}
                        </Box>
                    </Box>
                ))}
            </Box>
        </Container>
    )
}