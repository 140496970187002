import {ActionIcon, Badge, Checkbox, createStyles, getStylesRef, Group, Text} from "@mantine/core";
import {IconRestore, IconTrashOff} from "@tabler/icons-react";
import {useState} from "react";
import {deleteTask, unarchiveTask} from "../services/api";

const useStyles = createStyles((theme) => ({


    item: {
        color: theme.colors.gray[6],
        borderRadius: theme.radius.sm,
        paddingTop: theme.spacing.xs,
        paddingBottom: theme.spacing.xs,
        paddingLeft: theme.spacing.sm,
        paddingRight: theme.spacing.sm,
        '&:hover': {
            backgroundColor: theme.colors.gray[1],
            [`& .${getStylesRef('actions')}`]: {
                display: "flex"
            },
        },
    },
    actions: {
        ref: getStylesRef('actions'),
        display: "none"
    },
    content: {
        lineHeight: "1.75rem",
    },
    mark: {
        color: theme.colors.red[9],
        fontWeight: "bold",
        paddingRight: theme.spacing.xs
    }

}));

interface Props {
    id: string,
    priority: number,
    content: string,
    group: string,
    task_type: "Todo" | "Stateful",
    create_at: string,
    events: any[],
    done: boolean,
    archived: boolean,
    current_state: string,
    states: string[],
    habit_id?: string,
    habit_name?: string
}

export default function ArchivedTaskItem(props: Props) {
    const {classes, cx} = useStyles();
    const [checked] = useState(props.done);

    return (
        <Group className={cx(classes.item,)} position={"apart"}>
            <Group>
                <Text c="dimmed">{props.group}</Text>
                {props.task_type === "Todo" && <Checkbox disabled={true} checked={checked}/>}
                {props.task_type === "Stateful" &&
                    <Badge color="gray" size="md" variant="dot">{props.current_state}</Badge>}

                <div className={classes.content}>
                    {props.priority > 0 && <span className={classes.mark}>{"!".repeat(props.priority)}</span>}
                    {props.content}
                </div>
                {props.habit_id && <Badge color="gray" variant="outline">{props.habit_name}</Badge>}
            </Group>
            <Group spacing={"xs"} className={classes.actions}>
                <ActionIcon onClick={() => unarchiveTask(props.id)}>
                    <IconRestore size="1.125rem"/>
                </ActionIcon>
                <ActionIcon color="pink" onClick={() => deleteTask(props.id)}>
                    <IconTrashOff size="1.125rem"/>
                </ActionIcon>
            </Group>
        </Group>
    )
}