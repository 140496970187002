import {Box, Text} from "@mantine/core";
import {ReactNode} from "react";

interface Props {
    name: string,
    children: ReactNode | ReactNode[]
}

export default function TaskGroup(props: Props) {

    return (
        <Box mt={"1rem"}>
            <Text c="dimmed">{props.name}</Text>

            <Box>
                {props.children}
            </Box>

        </Box>

    )
}