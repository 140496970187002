import React, {FormEvent} from 'react'
import useUserSession from "../hooks/useUserSession";

import {useToggle} from '@mantine/hooks';
import {useForm} from '@mantine/form';
import {
    Anchor,
    Button,
    Container,
    Group,
    Paper,
    PaperProps,
    PasswordInput,
    Stack,
    TextInput,
    Title,
} from '@mantine/core';
import {api} from "../services/api";
import {AxiosError} from "axios/index";
import {notifications} from "@mantine/notifications";
import {IconCheck} from "@tabler/icons-react";


function Login(props: PaperProps) {
    const {signIn} = useUserSession()
    const [type, toggle] = useToggle(['login', 'register']);
    const form = useForm({
        initialValues: {
            email: '',
            username: '',
            password: '',
            terms: true,
        },

        validate: {
            username: (val) => type === "login" ? null : val.length <= 2 ? "Username should be at least 3 characters" : null,
            email: (val) => (/^\S+@\S+$/.test(val) ? null : 'Invalid email'),
            password: (val) => (val.length < 6 ? 'Password should include at least 6 characters' : null),
        },
    });


    async function handleSubmit(values: any, e: FormEvent) {
        e.preventDefault()
        if (type === "login") {
            try {
                await signIn({email: values.email, password: values.password})

            } catch (error) {
                const err = error as AxiosError
                notifications.show({
                    title: '登录失败',
                    // @ts-ignore
                    message: err.response?.data.toString() ?? "",
                    color: "red"
                })
            }

        }

        if (type === "register") {
            try {
                const response = await api.post('/users', {
                    username: values.username,
                    email: values.email,
                    password: values.password
                })
                if (response.status === 200) {
                    toggle()
                    notifications.show({
                        title: '注册成功🎉🎉🎉',
                        message: '现在可以登录了',
                        icon: <IconCheck/>
                    })
                } else {
                    console.log("hello")
                    notifications.show({
                        title: '注册失败',
                        message: response.data,
                        icon: <IconCheck/>,
                        color: "green"
                    })
                }
            } catch (error) {
                const err = error as AxiosError
                notifications.show({
                    title: '注册失败',
                    // @ts-ignore
                    message: err.response?.data.toString() ?? "",
                    color: "red"
                })
            }
        }
    }

    return (
        <Container size={"sm"}>
            <Stack mih={"100vh"} justify="center">
                <Paper radius="md" p="xl" withBorder {...props}>
                    <Title order={2} ta="center" my={"4rem"}>
                        {type === 'register'
                            ? '注册'
                            : "登录"}
                        Toodoo.top

                    </Title>
                    <form onSubmit={form.onSubmit(handleSubmit)}>
                        <Stack>
                            {type === 'register' && (
                                <TextInput
                                    size="md"
                                    required
                                    placeholder="Your name"
                                    value={form.values.username}
                                    onChange={(event) => form.setFieldValue('username', event.currentTarget.value)}
                                    error={form.errors.username && 'Invalid username'}

                                />
                            )}

                            <TextInput
                                size="md"
                                required
                                placeholder="You email "
                                value={form.values.email}
                                onChange={(event) => form.setFieldValue('email', event.currentTarget.value)}
                                error={form.errors.email && 'Invalid email'}

                            />

                            <PasswordInput
                                size="md"
                                required
                                placeholder="Your password"
                                value={form.values.password}
                                onChange={(event) => form.setFieldValue('password', event.currentTarget.value)}
                                error={form.errors.password && 'Password should include at least 6 characters'}

                            />


                        </Stack>

                        <Group position="apart" mt={"3rem"}>
                            <Anchor
                                component="button"
                                type="button"
                                color="dimmed"
                                onClick={() => toggle()}
                                size="sm"
                            >
                                {type === 'register'
                                    ? '已经注册？ 前往登录'
                                    : "注册新账号"}
                            </Anchor>
                            <Button type="submit" size="md" radius="xl">
                                {type === 'login' ? "登录" : "提交注册"}
                            </Button>
                        </Group>
                    </form>
                </Paper>
            </Stack>
        </Container>

    )
}

export default Login
