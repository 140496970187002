import {Badge, Button, Checkbox, createStyles, getStylesRef, Group, rem, Text} from "@mantine/core";
import {useState} from "react";

const useStyles = createStyles((theme) => ({


    item: {
        borderRadius: theme.radius.sm,
        paddingTop: theme.spacing.xs,
        paddingBottom: theme.spacing.xs,
        paddingLeft: theme.spacing.sm,
        paddingRight: theme.spacing.sm,
        '&:hover': {
            backgroundColor: theme.colors.gray[1],
            [`& .${getStylesRef('actions')}`]: {
                display: "flex"
            },
        },
    },
    actions: {
        ref: getStylesRef('actions'),
        display: "none"
    },
    content: {
        lineHeight: "1.75rem",
    },
    checked: {
        textDecoration: "line-through",
        color: theme.colors.gray[6]
    },
    mark: {
        color: theme.colors.red[9],
        fontWeight: "bold",
        paddingRight: theme.spacing.xs
    },
    group: {
        minWidth: rem("5vh")
    }

}));

interface Props {
    id: string,
    priority: number,
    content: string,
    group?: string,
    task_type: "Todo" | "Stateful",
    done: boolean,
    archived: boolean,
    current_state?: string,
    states?: string[],
    habit_id?: string,
    habit_name?: string
}

export default function PreviewTaskItem(props: Props) {

    const {classes, cx} = useStyles();
    const [checked] = useState(props.done);

    const current_index = (props.states ?? []).findIndex(it => it === props.current_state)
    const prevState = props.states?.[current_index - 1]
    const nextState = props.states?.[current_index + 1]

    const group = props.group ?? "default";
    return (
        <Group className={cx(classes.item)} position={"apart"}>
            <Group>
                <Text c="dimmed" className={classes.group}>{group}</Text>
                {props.task_type === "Todo" &&
                    <Checkbox color="gray" disabled={props.archived} checked={checked} readOnly/>}
                {props.task_type === "Stateful" &&
                    <Badge color="gray" size="md" variant="dot">{props.current_state}</Badge>}
                <div className={cx(classes.content, {[classes.checked]: props.done || props.archived})}>
                    {props.priority > 0 && <span className={classes.mark}>{"!".repeat(props.priority)}</span>}
                    {props.content}
                </div>
                {props.habit_id && <Badge color="gray" variant="outline">{props.habit_name}</Badge>}
                {props.archived && <Badge color="gray" variant="outline">ARCHIVED</Badge>}

            </Group>
            <Group spacing={"xs"} className={classes.actions}>

                {(!props.archived && prevState !== undefined) &&
                    <Button color="gray" size="xs" compact>
                        Back to {prevState}
                    </Button>
                }
                {(!props.archived && nextState !== undefined) &&
                    <Button size="xs" compact>
                        Goto {nextState}
                    </Button>
                }
            </Group>
        </Group>
    )
}