import {Button, Container, createStyles, Group, rem, SimpleGrid, Text, Title} from "@mantine/core";
import {IconApi, IconLayoutList, IconRotateRectangle, IconTemplate, IconThumbUp} from "@tabler/icons-react";
import {Link} from "react-router-dom";
import useUserSession from "../hooks/useUserSession";


const useStyles = createStyles((theme) => ({
    inner: {
        display: 'flex',
        justifyContent: 'space-between',
        paddingTop: `calc(${theme.spacing.xl} * 4)`,
        paddingBottom: `calc(${theme.spacing.xl} * 4)`,
    },

    sloganContent: {
        // maxWidth: rem(480),
        marginRight: `calc(${theme.spacing.xl} * 3)`,

        [theme.fn.smallerThan('md')]: {
            maxWidth: '100%',
            marginRight: 0,
        },
    },

    sloganTitle: {
        color: theme.colorScheme === 'dark' ? theme.white : theme.black,
        fontFamily: `Greycliff CF, ${theme.fontFamily}`,
        fontSize: rem(44),
        lineHeight: 1.2,
        fontWeight: 900,

        [theme.fn.smallerThan('xs')]: {
            fontSize: rem(28),
        },
    },

    control: {
        [theme.fn.smallerThan('xs')]: {
            flex: 1,
        },
    },

    image: {
        flex: 1,

        [theme.fn.smallerThan('md')]: {
            display: 'none',
        },
    },

    highlight: {
        position: 'relative',
        backgroundColor: theme.fn.variant({variant: 'light', color: theme.primaryColor}).background,
        borderRadius: theme.radius.sm,
        padding: `${rem(4)} ${rem(12)}`,
    },

    feature: {
        position: 'relative',
        paddingTop: theme.spacing.xl,
        paddingLeft: theme.spacing.xl,
    },

    overlay: {
        position: 'absolute',
        height: rem(100),
        width: rem(160),
        top: 0,
        left: 0,
        backgroundColor: theme.fn.variant({variant: 'light', color: theme.primaryColor}).background,
        zIndex: 1,
    },

    content: {
        position: 'relative',
        zIndex: 2,
    },

    icon: {
        color: theme.fn.variant({variant: 'light', color: theme.primaryColor}).color,
    },

    title: {
        color: theme.colorScheme === 'dark' ? theme.white : theme.black,
    },
}));


interface FeatureProps extends React.ComponentPropsWithoutRef<'div'> {
    icon: React.FC<any>;
    title: string;
    description: string;
}

function Feature({icon: Icon, title, description, className, ...others}: FeatureProps) {
    const {classes, cx} = useStyles();
    return (
        <div className={cx(classes.feature, className)} {...others}>
            <div className={classes.overlay}/>

            <div className={classes.content}>
                <Icon size={rem(38)} className={classes.icon} stroke={1.5}/>
                <Text fw={700} fz="lg" mb="xs" mt={5} className={classes.title}>
                    {title}
                </Text>
                <Text c="dimmed" fz="sm">
                    {description}
                </Text>
            </div>
        </div>
    );
}

const mockdata = [
    {
        icon: IconRotateRectangle,
        title: '基于状态机的待办事项',
        description:
            '相比于传统的待办清单，Toodoo 提供了可以在多个状态之间流转的模式，可以更轻松地管理更加复杂、流程更长的事项。',
    },
    {
        icon: IconTemplate,
        title: '模版：别重复自己',
        description:
            '足够实用的模版系统让 Toodoo 在新建待办事项的时候变得更加简单、更加直观。',
    },
    {
        icon: IconLayoutList,
        title: '时间线：回顾你的成就',
        description:
            '按照完成日期排序的时间线，在回顾过往待办事项时更自在，让站会的陈述面面俱到、一点不漏。',
    },
    {
        icon: IconThumbUp,
        title: '习惯：变成更好的自己',
        description:
            '内置的习惯系统可以方便地自动创建、追踪每日重复性任务，让学习追踪、健身追踪等习惯更加轻松、自在。',
    },
    {
        icon: IconApi,
        title: '开发者友好型',
        description:
            'Toodoo 提供了完整的数据导出、账号删除等隐私性完整控制。',
    },
];


function Homepage() {
    const {classes} = useStyles();
    const {isAuthenticated, user, loadingUserData} = useUserSession()

    const isAuthed = !loadingUserData && isAuthenticated;

    const items = mockdata.map((item) => <Feature {...item} key={item.title}/>);

    return (
        <div>
            <Container mt={"4rem"}>
                <div className={classes.sloganContent}>
                    <Title className={classes.sloganTitle} mb={"1rem"}>
                        {isAuthed &&
                            <>{user?.username}, 欢迎回到 </>
                        }
                        <span className={classes.highlight}>Toodoo</span>

                    </Title>
                    <Title className={classes.sloganTitle}>
                        直观、易用，且强大的<span className={classes.highlight}>待办清单</span>
                    </Title>
                    <Text color="dimmed" mt="md">
                    </Text>
                    <Group mt={"4rem"}>
                        <Button component={Link} to={isAuthed ? "/tasks" : "/login"} radius="xl" size="md"
                                className={classes.control}>
                            {isAuthed ? "前往今日待办" : "已有账号，登录"}
                        </Button>
                        {!isAuthed &&
                            <Button component={Link} to={"/login?type=register"} variant="default" radius="xl" size="md"
                                    className={classes.control}>
                                前往注册
                            </Button>
                        }
                    </Group>
                </div>
                <SimpleGrid mt={"6rem"} cols={2} breakpoints={[{maxWidth: 'sm', cols: 1}]} spacing={50}>
                    {items}
                </SimpleGrid>
            </Container>
        </div>
    )
}

export default Homepage
