import {
    ActionIcon,
    Badge,
    Box,
    Button,
    Checkbox,
    createStyles,
    getStylesRef,
    Group,
    px,
    rem,
    Text
} from "@mantine/core";
import {ChangeEvent, useState} from "react";
import {IconArchive, IconEditCircle, IconRestore, IconTrashOff} from "@tabler/icons-react";
import {openContextModal} from "@mantine/modals";
import dayjs, {Dayjs} from "dayjs";
import {eventTypeConverter} from "../pages/TaskDetail";
import {Link} from 'react-router-dom'

import {ItemLineItem, TaskEvent} from "../lib/schema";
import {api, archiveTask, deleteTask, unarchiveTask, updateTaskStatus} from "../services/api";

const useStyles = createStyles((theme) => ({
    item: {
        borderRadius: theme.radius.sm,
        paddingTop: theme.spacing.xs,
        paddingBottom: theme.spacing.xs,
        paddingLeft: theme.spacing.sm,
        paddingRight: theme.spacing.sm,
        '&:hover': {
            backgroundColor: theme.colors.gray[1],
            [`& .${getStylesRef('actions')}`]: {
                display: "flex"
            },
        },
    },
    actions: {
        ref: getStylesRef('actions'),
        display: "none"
    },
    content: {
        lineHeight: "1.75rem",
    },
    checked: {
        textDecoration: "line-through",
        color: theme.colors.gray[6]
    },
    mark: {
        color: theme.colors.red[9],
        fontWeight: "bold",
        paddingRight: theme.spacing.xs
    },
    group: {
        minWidth: rem("5vh")
    },
    event: {
        marginLeft: px(theme.spacing.xl) * 3.3,
        color: theme.colors.gray[7],
        fontSize: theme.fontSizes.xs,
        paddingTop: px(theme.spacing.xs) * 0.25,
        paddingBottom: px(theme.spacing.xs) * 0.25,
    }

}));

interface Props extends ItemLineItem {


    grouped_day?: Dayjs,
}

export default function TaskItemDetail(props: Props) {

    const {classes, cx} = useStyles();
    const [checked, setChecked] = useState(props.done);


    const handleClick = async (e: ChangeEvent<HTMLInputElement>) => {
        setChecked(e.currentTarget.checked)

        const status = e.currentTarget.checked ? "Done" : "Open"
        await updateTaskStatus(props.id, status);
    }

    const handleUpdateState = async (state: string) => {
        await updateTaskStatus(props.id, state);
    }

    const openEditModel = () => {
        openContextModal({
            modal: "taskItemEditModal",
            title: 'Editing task Item',
            centered: true,
            size: "xl",
            innerProps: props,
        })
    }

    const current_index = (props.states ?? []).findIndex(it => it === props.current_state)
    const prevState = props.states?.[current_index - 1]
    const nextState = props.states?.[current_index + 1]
    let day_events: TaskEvent[] = [];
    if (props.grouped_day !== undefined) {
        day_events = props.events
            .filter(event => dayjs(event.datetime).format("MMM D, YYYY") === dayjs(props.grouped_day).format("MMM D, YYYY"))
            .reverse();
    }

    return (
        <Box>
            <Group className={cx(classes.item)} position={"apart"}>
                <Group>
                    <Text c="dimmed" className={classes.group}>{props.group}</Text>
                    {props.task_type === "Todo" &&
                        <Checkbox color="gray" disabled={props.archived} checked={checked} onChange={handleClick}/>}
                    {props.task_type === "Stateful" &&
                        <Badge color="gray" size="md" variant="dot">{props.current_state}</Badge>}

                    <Box component={Link} to={`/tasks/${props.id}`}
                         className={cx(classes.content, {[classes.checked]: props.done || props.archived})}>
                        {props.priority > 0 && <span className={classes.mark}>{"!".repeat(props.priority)}</span>}
                        {props.content}
                    </Box>
                    {props.habit_id && <Badge color="gray" variant="outline">{props.habit_name}</Badge>}
                    {props.archived && <Badge color="gray" variant="outline">ARCHIVED</Badge>}

                </Group>
                <Group spacing={"xs"} className={classes.actions}>
                    {(!props.archived && prevState !== undefined) &&
                        <Button color="gray" size="xs" compact onClick={() => handleUpdateState(prevState)}>
                            Back to {prevState}
                        </Button>
                    }
                    {(!props.archived && nextState !== undefined) &&
                        <Button size="xs" compact onClick={() => handleUpdateState(nextState)}>
                            Goto {nextState}
                        </Button>
                    }
                    {!props.done && !props.archived &&
                        <ActionIcon onClick={() => openEditModel()}>
                            <IconEditCircle size="1.125rem"/>
                        </ActionIcon>
                    }
                    {props.archived ?
                        <ActionIcon onClick={() => unarchiveTask(props.id)}>
                            <IconRestore size="1.125rem"/>
                        </ActionIcon>
                        : <ActionIcon onClick={() => archiveTask(props.id)}>
                            <IconArchive size="1.125rem"/>
                        </ActionIcon>}
                    <ActionIcon color="pink" onClick={() => deleteTask(props.id)}>
                        <IconTrashOff size="1.125rem"/>
                    </ActionIcon>
                </Group>
            </Group>
            {day_events.map(event => <Group className={classes.event}>
                <Text>{dayjs(event.datetime).format("HH:mm:ss")}</Text>
                <Text>{eventTypeConverter(event)}</Text>
            </Group>)}
        </Box>


    )
}