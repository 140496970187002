import React, {ChangeEvent, useEffect, useState} from "react";
import {ActionIcon, Badge, Box, Button, Checkbox, createStyles, getStylesRef, Group, Loader, Text} from "@mantine/core";
import {IconArchive, IconEditCircle, IconMessage2, IconTrashOff} from "@tabler/icons-react";
import dayjs from "dayjs";
import {Link} from 'react-router-dom'
import {openContextModal} from "@mantine/modals";
import {archiveTask, deleteTask, updateTaskStatus} from "../services/api";

const useStyles = createStyles((theme) => ({
    item: {
        borderRadius: theme.radius.sm,
        paddingTop: theme.spacing.xs,
        paddingBottom: theme.spacing.xs,
        paddingLeft: theme.spacing.sm,
        paddingRight: theme.spacing.sm,
        '&:hover': {
            backgroundColor: theme.colors.gray[1],
            [`& .${getStylesRef('actions')}`]: {
                display: "flex"
            },
        },
    },
    content: {
        lineHeight: "1.75rem",
        color: theme.colors.gray[9],
        textDecoration: "none"
    },
    actions: {
        ref: getStylesRef('actions'),
        display: "none"
    },
    checked: {
        textDecoration: "line-through",
        color: theme.colors.gray[6]
    },
    mark: {
        color: theme.colors.red[9],
        fontWeight: "bold",
        paddingRight: theme.spacing.xs
    }

}));

interface Props {
    id: string,
    priority: number,
    content: string,
    group: string,
    task_type: "Todo" | "Stateful",
    create_at: string,
    events: any[],
    done: boolean,
    archived: boolean,
    current_state: string,
    states: string[],
    habit_id?: string,
    habit_name?: string,
    comments: any[]
}

export default function TaskItem(props: Props) {
    const {classes, cx} = useStyles();
    const [checked, setChecked] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    useEffect(() => {
        setChecked(props.done)
    }, [props])

    const handleClick = async (e: ChangeEvent<HTMLInputElement>) => {
        setIsLoading(true);
        setChecked(e.currentTarget.checked)
        const status = e.currentTarget.checked ? "Done" : "Open"
        await updateTaskStatus(props.id, status);
        setIsLoading(false);
    }
    const handleUpdateState = async (state: string) => {
        setIsLoading(true);
        await updateTaskStatus(props.id, state);
        setIsLoading(false);
    }

    const openEditModel = () => {
        openContextModal({
            modal: "taskItemEditModal",
            title: 'Editing task Item',
            centered: true,
            size:"xl",
            innerProps: props,
        })
    }
    const current_index = props.states?.findIndex(it => it === props.current_state)
    const prevState = props.states?.[current_index - 1]
    const nextState = props.states?.[current_index + 1]
    const standDuration = Math.trunc((dayjs().unix() - dayjs(props.events[0].datetime).unix()) / 86400);
    return (
        <Group className={cx(classes.item)} position={"apart"}>
            <Group spacing={"xs"}>

                {isLoading ? <Loader size="xs"/> :
                    <>
                        {props.task_type === "Todo" &&
                            <Checkbox color="gray" checked={checked} onChange={handleClick}/>}
                        {props.task_type === "Stateful" &&
                            <Badge color="indigo" size="md" variant="dot">{props.current_state}</Badge>}
                    </>
                }
                <Box component={Link} to={`/tasks/${props.id}`}
                     className={cx(classes.content, {[classes.checked]: props.done || props.archived})}>
                    {props.priority > 0 && <span className={classes.mark}>{"!".repeat(props.priority)}</span>}
                    {props.content}
                </Box>
                {props.comments.length >0 && <Text size="sm" color="dimmed"><IconMessage2 size="1.125rem"></IconMessage2></Text>}

                {props.habit_id && <Badge color="pink" variant="outline">{props.habit_name}</Badge>}
                {standDuration > 0 && <Badge color="red" variant="filled">任务持续超过 {standDuration} 天</Badge>}
            </Group>
            <Group spacing={"xs"} className={classes.actions}>
                {prevState !== undefined &&
                    <Button color="gray" size="xs" compact onClick={() => handleUpdateState(prevState)}>
                        Back to {prevState}
                    </Button>
                }
                {nextState !== undefined &&
                    <Button size="xs" compact onClick={() => handleUpdateState(nextState)}>
                        Goto {nextState}
                    </Button>
                }

                {!props.done && !props.archived &&
                    <ActionIcon onClick={() => openEditModel()}>
                        <IconEditCircle size="1.125rem"/>
                    </ActionIcon>
                }
                <ActionIcon onClick={() => archiveTask(props.id)}>
                    <IconArchive size="1.125rem"/>
                </ActionIcon>
                <ActionIcon color="pink" onClick={() => deleteTask(props.id)}>
                    <IconTrashOff size="1.125rem"/>
                </ActionIcon>
            </Group>
        </Group>
    )
}