import NavBar from "../components/NavBar";
import {Accordion, Box, Button, Chip, Container, Divider, Grid, Group, TextInput} from "@mantine/core";
import {useState} from "react";
import {parseTask} from "../utils/taskParser";
import {api, fetcher} from "../services/api";
import useSWR, {mutate} from "swr";
import HabitItem from "../components/HabitItem";

export default function Habits() {
    const [name, setName] = useState("");
    const [newTaskText, setNewTaskText] = useState("");

    const [triggerDays, setTriggerDays] = useState<string[]>([]);

    const {data: habits, isLoading} = useSWR<any[]>("/habits", fetcher);
    const handleSubmit = async () => {
        const data = parseTask(newTaskText);
        await api.post("/habits", {
            trigger_days: triggerDays,
            name: name.trim(),
            ...data
        });
        setNewTaskText("")
        setName("")
        await mutate("/habits")
    }

    return (
        <Container mt={"3rem"}>
            <NavBar/>

            <Accordion variant="contained" mt={"1rem"}>
                <Accordion.Item value="archivedTasks">
                    <Accordion.Control>
                        新增习惯
                    </Accordion.Control>
                    <Accordion.Panel>
                        <Grid mt={"1rem"}>
                            <Grid.Col span={12}><TextInput
                                size="md"
                                value={name}
                                onChange={e => setName(e.target.value)}
                                placeholder="habit name"
                            /></Grid.Col>
                            <Grid.Col span={12}><TextInput
                                size="md"
                                value={newTaskText}
                                onChange={e => setNewTaskText(e.target.value)}
                                placeholder="Input a new habit"
                            /></Grid.Col>
                            <Grid.Col span={12}><Group spacing={"xs"}>
                                <Chip.Group multiple value={triggerDays} onChange={setTriggerDays}>
                                    <Chip variant="filled" value="Sunday">日</Chip>
                                    <Chip variant="filled" value="Monday">一</Chip>
                                    <Chip variant="filled" value="Tuesday">二</Chip>
                                    <Chip variant="filled" value="Wednesday">三</Chip>
                                    <Chip variant="filled" value="Thursday">四</Chip>
                                    <Chip variant="filled" value="Friday">五</Chip>
                                    <Chip variant="filled" value="Saturday">六</Chip>
                                </Chip.Group>
                            </Group>
                            </Grid.Col>
                            <Grid.Col span={12}><Button
                                disabled={name.trim().length === 0 || newTaskText.trim().length === 0 || triggerDays.length === 0}
                                onClick={handleSubmit}>创建</Button></Grid.Col>
                        </Grid>
                    </Accordion.Panel>
                </Accordion.Item>
            </Accordion>

            <Divider variant="dashed" my={"1rem"}></Divider>

            {!isLoading && (habits ?? []).map(habit => <Box key={habit.id} mt={"1rem"}>
                <HabitItem {...habit}/>
            </Box>)}


        </Container>
    )
}