import React from 'react';
import {BrowserRouter} from "react-router-dom";
import AuthProvider from "./providers/AuthProvider";
import RouteList from "./router/RouteList";
import {MantineProvider} from "@mantine/core";
import {Notifications} from "@mantine/notifications";
import {ModalsProvider} from '@mantine/modals';
import {modals} from "./modals";

function App() {
    return (
        <MantineProvider withGlobalStyles withNormalizeCSS>
            <ModalsProvider modals={modals}>
                <Notifications position="top-right"/>
                <BrowserRouter>
                    <AuthProvider>
                        {/*<NavBar/>*/}
                        <RouteList/>
                    </AuthProvider></BrowserRouter>
                </ModalsProvider>
            </MantineProvider>
    );
}

export default App;
